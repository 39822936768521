import React, { useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';

const DateInput = ({
  selectedDate,
  handleInputChangeEdit,
  itemId,
  field,
  disabled
}) => {
  const isInitialMount = useRef(true);
  const today = new Date();
  const defaultFormattedDate = today.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  });
  const dateValue = selectedDate ? new Date(Date.parse(selectedDate)) : null;

  useEffect(() => {
    if (isInitialMount.current) {
      if (!selectedDate) {
        handleInputChangeEdit(defaultFormattedDate, itemId, field);
      }
      isInitialMount.current = false;
    }
  }, [selectedDate, defaultFormattedDate, handleInputChangeEdit, itemId, field]);

  const handleChooseDate = (date) => {
    if (date) {
      const formattedDate = date.toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
      });
      handleInputChangeEdit(formattedDate, itemId, field);
    } else {
      handleInputChangeEdit(null, itemId, field);
    }
  };

  return (
    <StyledDateInput>
      <InputDatePicker
        id="date-input"
        selected={dateValue}
        onChange={handleChooseDate}
        dateFormat="MMMM d, yyyy"
        placeholderText="Click to select a date"
        disabled={disabled}
        // isClearable
        todayButton="Today"
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled
        }) => (
          <HeaderContainer>
            <ArrowContainer>
              <HeaderButton onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                {"<"}
              </HeaderButton>
            </ArrowContainer>
            <FixedHeaderText>
              {date.toLocaleString('default', { month: 'long' })} {date.getFullYear()}
            </FixedHeaderText>
            <ArrowContainer>
              <HeaderButton onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                {">"}
              </HeaderButton>
            </ArrowContainer>
            <ClearButtonHeader type="button" onClick={() => handleChooseDate(null)}>
              Clear Date
            </ClearButtonHeader>
          </HeaderContainer>
        )}
      />
    </StyledDateInput>
  );
};

export default DateInput;

const StyledDateInput = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 100px;
`;

/* Wraps the whole header. */
const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  background-color: #f1f1f1;
`;

/* Left/right arrow container with a fixed width. */
const ArrowContainer = styled.div`
  width: 40px; /* Fixed width for arrow containers */
  text-align: center;
`;

/* Fixed width element for Month Year text. */
const FixedHeaderText = styled.span`
  width: 120px;         /* Fixed width for text */
  text-align: center;   /* Centers text within the container */
  display: inline-block;
  font-size: 14px;
  margin: 0 8px;
`;

const HeaderButton = styled.button`
  font-size: 14px;
  padding: 4px 8px;
  border: 1px solid #ccc;
  background-color: #fff;
  cursor: pointer;
  &:hover {
    background-color: #eee;
  }
  &:disabled {
    cursor: default;
    opacity: 0.5;
  }
`;

const ClearButtonHeader = styled.button`
  font-size: 12px;
  padding: 4px 8px;
  border: 1px solid #ccc;
  background-color: #fff;
  cursor: pointer;
  margin-left: 8px;
  &:hover {
    background-color: #eee;
  }
`;

const InputDatePicker = styled(DatePicker)`
    white-space: nowrap;
    font-size: 14px;
    height: 21px;
    width: 100px;  // Adjusted width from 100px to 80px
    border-bottom: 2px solid grey; 
    border-right: 2px solid grey;
    margin-right: 10px;
    
    &:hover {
        cursor: pointer;
    }
    
    &:disabled {
        cursor: default;
        opacity: 0.5;
    }
`;
