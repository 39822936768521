import React, { useContext } from "react";
import styled from "styled-components";
import { CurrentUserContext } from "../security/CurrentUserContext";

// Container for the entire profile page
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background-color: #f9f9f9;
  min-height: 100vh;
`;

// Header section for the profile page
const Header = styled.header`
  width: 100%;
  padding: 20px 40px;
  background-color: #004080;
  color: #ffffff;
  text-align: center;
`;

// Main content area for the profile information
const ProfileContent = styled.div`
  background-color: #ffffff;
  padding: 30px;
  width: 90%;
  max-width: 800px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: -30px;
  border-radius: 8px;
`;

// Styled avatar image; a placeholder is used if no custom avatar is available
const Avatar = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 4px solid #ffffff;
  object-fit: cover;
  margin-top: -60px;
  background-color: #dddddd;
`;

// Container for textual user details
const Details = styled.div`
  margin-top: 20px;
  text-align: center;
`;

// User name styling
const UserName = styled.h2`
  margin: 10px 0;
  font-size: 28px;
  color: #333333;
`;

// Description styling for additional user information
const Description = styled.p`
  font-size: 16px;
  color: #666666;
  line-height: 1.6;
`;

const ProfilePage = () => {
  const {
    currentUserState: {
      loading,
      first_name,
      last_name,
      email,
      phone,
      date_created
    }
  } = useContext(CurrentUserContext);

  if (loading) {
    return (
      <PageContainer>
        <Header>
          <h1>Profile Page</h1>
        </Header>
        <ProfileContent>
          <p>Loading user information...</p>
        </ProfileContent>
      </PageContainer>
    );
  }

  // Use a placeholder image for the avatar
  const avatarUrl = "https://via.placeholder.com/120";

  return (
    <PageContainer>
      <Header>
        <h1>Profile Page</h1>
      </Header>
      <ProfileContent>
        <Avatar src={avatarUrl} alt={`${first_name} ${last_name} Avatar`} />
        <Details>
          <UserName>
            {first_name} {last_name}
          </UserName>
          <Description>Email: {email}</Description>
          <Description>Phone: {phone}</Description>
          <Description>
            Member since: {new Date(date_created).toLocaleDateString()}
          </Description>
        </Details>
      </ProfileContent>
    </PageContainer>
  );
};

export default ProfilePage;
